<template>
  <div class="">
     
   

   

    <!-- Shopify Modal -->
    <ModalVue
      ref="Modal"
      id="copyShopifyProductsVariants"
      title="Sync variants as products"
      size="lg">
      <div class="my-2" :class="!isLoadingSync ? 'd-none' : 'd-block'">
        <ProgressbarVue ref="progressbar"></ProgressbarVue>
        <span
          class="text-success text-center"
          style="font-size: 12px; text-align: center">
          Products syncing soon, continue tasks uninterrupted.
        </span>
      </div>

      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="d-flex align-items-center h-100">
            <FilterBox
              style="flex:1"
              :search="true"
              placeholder="Search Products by title or SKU"
              @input="searchProductQueryHandle($event)"></FilterBox>
          </div>
        </div>
        
       
      </div>
      <div class="loader px-2">
        <div
            class="d-flex align-items-center "
            v-if="isLoadingShopifyProducts">
            <b-spinner small style="color: #4d1b7e"></b-spinner>
            <span class="ml-2">Loading Products</span>
          </div>
      </div>
      <b-skeleton-table
        v-if="isLoadingShopifyProductFirstTime"
        :rows="5"
        :columns="3"
        :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
      <div class="data-table" v-else-if="shopifyProducts.length">
        <div
          class=""
          style="max-height:52vh; min-height: 52h; overflow: auto">
          <b-table
          :sticky-header="true"
          :no-border-collapse="true"
         
            head-class="text-center"
            class="leadTable"
            :stickyColumn="true"
            hover
            :fields="shopifyProductsFields"
            :items="shopifyProducts">
            <template #head(checkbox)>
              <b-form-checkbox
                size="lg"
                :disabled="
                  isLoadingShopifyProducts ||
                  isLoadingSelectedShopifyProducts ||
                  isLoadingSync
                "
                v-model="shopifySelectAllCheck"
                @change="shopifyProductSelectAllToggle($event)"
                class="checkbox selectAll"></b-form-checkbox>
            </template>
            <template #cell(checkbox)="data">
              <b-form-checkbox
                :value="data.item"
                v-model="shopifySelectedProducts"
               
                :disabled="
                  isLoadingShopifyProducts ||
                  isLoadingSelectedShopifyProducts ||
                  isLoadingSync
                "
                size="lg"
                class="checkbox"></b-form-checkbox>
            </template>
            <template #cell(pic)="data">
              <div class="d-flex gap align-items-center">
                <lazy-image
  class="circle-image mr-2"
  :src="data.item.image? data.item.image: 'https://images.quizell.com/website/default_start_page.png'"
  :key="data.index+data.item.displayName"
></lazy-image>
                <div class="">{{ data.item.displayName }}</div>
              </div>
            
            </template>
              
          
          </b-table>
        </div>
      </div>
      <template v-else>

<div class="d-flex justify-content-center align-items-center" style="height: 50vh;" >

<EmptyTable >
<template #title>
No product found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
      <div class="d-flex justify-content-between mt-2">
        <ShopifyPagination
          :hasNext="pageInfo.hasNextPage"
          @onNext="
            () => {
              queryObject.before = '';
              queryObject.after = pageInfo.endCursor;
              getProductsFromShopify();
            }
          "
          :hasPrevious="pageInfo.hasPreviousPage"
          @onPrevious="
            () => {
              queryObject.after = '';
              queryObject.before = pageInfo.startCursor;
              getProductsFromShopify();
            }
          "></ShopifyPagination>
          <div class="">
          <div
            class=""
            >
           
            <BorderButton
              :custom="true"
              @click="syncSelected('')"
              :disabled="
                isLoadingSync ||
                isLoadingShopifyProducts ||
                isLoadingSelectedShopifyProducts || shopifySelectedProducts.length<=0
              "
              :isLoading="isLoadingSelectedShopifyProducts"
              class="secondary-color-button mr-3"
              v-if="shopifySelectedProducts.length"
              >Sync Selected</BorderButton
            >
            <button
              :disabled="
                isLoadingSync ||
                isLoadingShopifyProducts ||
                isLoadingSelectedShopifyProducts
              "
               v-else
              class="btn d-flex px-3 justify-content-center align-items-center py-2 shopify-btn"
              @click="syncSelected('all')">
              <div v-if="isLoadingSync||isLoadingSelectedShopifyProducts" class="d-flex align-items-center">
                <b-spinner small></b-spinner>
                <span class="ml-1">Syncing...</span>
              </div>

              <span v-else>Sync All</span>
            </button>
          </div>
          
        </div>
      </div>
    </ModalVue>
  
      
  </div>
 
</template>
<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import EmptyTable from "../../Layout/EmptyTable.vue";
import ShopifyPagination from "../../Layout/ShopifyPagination.vue";
import FilterBox from "../../Layout/FilterBox.vue";
import BorderButton from '../../Layout/BorderButton.vue'
import ModalVue from "../../Layout/Modal.vue";
import ProgressbarVue from "../../Layout/Progressbar.vue";
import axios from "axios";
// import Button from '../../Layout/Button.vue';
export default {
  components: {
    FilterBox,
EmptyTable,
    ShopifyPagination,
    ModalVue,
    // Button,
    BorderButton,
    ProgressbarVue,
   
   
  },
  data() {
    return {
      syncType:'all',
      isLoadAddProduct:false,
      selectAllQuizellProducts: false,
      selectedQuizellProducts: [],
      searchProductText: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 10,
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        endCursor: "",
        startCursor: "",
      },
      queryObject:{
        before:'',
        after:''
      },
      isLoadingShopifyProducts: false,
      isLoadingShopifyProductFirstTime: false,
      shopifySelectAllCheck: false,
      shopifySelectedProducts: [],
    
      shopifyProducts: [],
      shopifyProductsFields: [
        {
          key: "checkbox",
          label: "",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "pic",
          label: "Product",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
        
        {
          key: "sku",
          label: "SKU",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        }, {
          key: "price",
          label: "Price",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },


        
      ],
      items: [],
      isLoadingFirstTime: true,
      isLoadingSelectedShopifyProducts: false,
      isLoadingDeleteProduct: false,
      isLoadingSync: false,
      isLoading: false,
      productProducts:[],
     

filterObject:{
  tags:[],
  vendor:[],
  collections:[]
},
filter: {sku:'', input: '' },
    };
  },
  watch: {


    filter:{
      handler(){
        this.handleSearchDebounced();
      },
      deep:true
    },
    allProducts:{
      handler({selected,all}){
       this.shopifySelectAllCheck=all.every(product=>selected.includes(product))
      },
      deep:true
    },
   
  },
  computed: {
    isHideSelectAllButton(){
     return this.filter.status.length>0||this.filter.vendor||this.filter.collection||this.filter.tag
    },
    ...mapGetters(["getNavbarData"]),
    allProducts(){
      return {selected:this.shopifySelectedProducts,all:this.shopifyProducts}
    },
  
    shopifyAllProducts() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.shopifyProducts
    },
  },
  methods: {
   
//    RowClick(e) {
//     const index = this.shopifySelectedProducts.indexOf(e);
//     if (index > -1) {
    
//         this.shopifySelectedProducts.splice(index, 1);
//     } else {
//         this.shopifySelectedProducts.push(e);
//     }
// },
    open() {
        this.isLoadingShopifyProductFirstTime = true;
      this.$refs.Modal.open();
      this.getProductsFromShopify();
      this.shopifySelectAllCheck=false;
      this.isLoadingShopifyProducts=false;
      this.shopifySelectedProducts=[]
    },
    async syncSelected(syncType) {
      
      this.isLoadingSelectedShopifyProducts = true;
      let payload={}
      if(syncType=='all')
     payload.search=this.filter.input
      else
      payload.selected_items = this.shopifySelectedProducts
      await axios
        .post("shopify/products-as-variants/import", payload)
        .then((response) => {
          this.$refs.Modal.close();
          this.$emit("updateProducts","/product/list");
          
          this.$toasted.show(response.data.message, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        })
        .catch((e) => {
          this.$toasted.show("Error occurred while syncing products", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          throw e
        });
      this.isLoadingSelectedShopifyProducts = false;
    },
    searchProductQueryHandle(e) {

      this.filter.input = e;
      
    },
    shopifyProductSelectAllToggle() {
      this.shopifySelectedProducts = [];
      if (this.shopifySelectAllCheck) {
        this.shopifySelectedProducts = this.shopifyAllProducts;
      }
    },
   
    async getProductsFromShopify() {

      this.isLoadingShopifyProducts = true;
  
    const payload={
      search: this.filter.input,
      ...this.queryObject
    }
     
      await axios
        .get("/shopify/products-as-variants/load",{params:payload})
        .then((response) => {
         
          if (!response.data.errors) {
            this.shopifyProducts=response.data.data.variants;
            this.pageInfo=response.data.data.pageInfo;
          
          } else {
            this.$toasted.show("Error occurred getting products. ", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
          
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoadingShopifyProducts = false;
      this.isLoadingShopifyProductFirstTime = false;
    },
 

    handleSearchDebounced: debounce(function () {
      this.getProductsFromShopify();
    }, 500), // 500ms debounce time, adjust as needed
   
  },
  mounted() {

  },
};
</script>
<style scoped>
.shopify-btn:active {
  background-color: #002e25;
  color: #ffffff;
  border-color: #002e25;
}
.shopify-btn {
  appearance: none;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #008060;
  color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  font-size: 14px;

  transition: 150ms ease;
  transition-property: background-color, border-color;
  display: inline-block;

  border-radius: 6px;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color) !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
}
.leadTable >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.leadTable >>> thead tr {
  border-radius: 10px;
}
.leadTable >>> thead th {
  border: none;
  vertical-align: middle !important;
}
.loader {
  min-height: 40px;
}
.product-image {
  height: 40px;
  width: 40px;
  /* border: 4px solid rgb(255, 255, 255); */
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
  /* padding: 0.25rem; */
  /* background-color: #fff; */
}
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
#product-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
#product-detail >>> button::after {
  display: none;
}
#product-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#product-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#product-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}
.leadTable>>>.table.b-table > thead > tr > .table-b-table-default{
background: #f4f3f4;
}
</style>
